import React, { useEffect, useState } from 'react'
import './list-package.css'
import { type PackageType } from '../../../utils/types'

import { ReactComponent as EditIcon } from '../../../assets/edit_icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/delete_icon.svg'
import imageService from '../../../services/imageService'
import ToggleButton from '../../utils/toggleButton/ToggleButton'
import PackageTable from '../packageTable/PackageTable'
import Modal from '../../utils/modal/Modal'
import EditPackage from '../editPackage/EditPackage'
import ConfirmationModal from '../../utils/confirmationModal/ConfirmationModal'
import packageService from '../../../services/packageService'
import InfoModal from '../../utils/infoModal/InfoModal'
import AddImagePackage from '../addImagePackage/AddImagePackage'

interface ListPackageProps {
  packagesList: PackageType[]
}

const ListPackage: React.FC<ListPackageProps> = ({ packagesList }) => {
  const [packages, setPackages] = useState<PackageType[]>(packagesList)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')
  const [infoModalTitle, setInfoModalTitle] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [currentPackage, setCurrentPackage] = useState<PackageType>()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null)
  const [isRankConfirmationModalOpen, setIsRankConfirmationModalOpen] =
    useState(false)
  const [rankToUpdate, setRankToUpdate] = useState<null | {
    pkg: PackageType
    newRank: number
    audienceId: number
  }>(null)
  const [
    isBulkDeleteConfirmationModalOpen,
    setIsBulkDeleteConfirmationModalOpen
  ] = useState(false)
  const [selectedPackages, setSelectedPackages] = useState<number[]>([])
  const [selectedPackage, setSelectedPackage] = useState<Partial<PackageType>>({
    code: '',
    label: '',
    minToOrder: null,
    maxToOrder: null,
    minToWinPoints: null,
    maxToWinPoints: null,
    pointValue: null
  })
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false)

  const handleProductRemoved = (packageId: number, productId: number): void => {
    setPackages((prevPackages) =>
      prevPackages.map((pkg) =>
        pkg.id === packageId
          ? {
              ...pkg,
              products: pkg.products.filter(
                (product) => product.id !== productId
              )
            }
          : pkg
      )
    )
  }

  const handleProductAdded = (packageId: number, newProduct: any): void => {
    setPackages((prevPackages) =>
      prevPackages.map((pkg) =>
        pkg.id === packageId
          ? {
              ...pkg,
              products: [...pkg.products, newProduct.Product]
            }
          : pkg
      )
    )
  }

  const handleProductUpdated = (
    packageId: number,
    updatedProduct: any
  ): void => {
    setPackages((prevPackages) =>
      prevPackages.map((pkg) =>
        pkg.id === packageId
          ? {
              ...pkg,
              products: pkg.products.map((product) =>
                product.id === updatedProduct.productId
                  ? {
                      ...product,
                      PackageProducts: { quantity: updatedProduct.quantity }
                    }
                  : product
              )
            }
          : pkg
      )
    )
  }

  const updatePackage = (newPackage: PackageType): void => {
    setExpandedRowIndex(null)
    setSelectedPackage(newPackage)
    setIsEditModalOpen(true)
  }

  const handleEditPackage = (updatedPackage: PackageType): void => {
    setPackages(
      packages.map((newPackage) =>
        newPackage.id === updatedPackage.id ? updatedPackage : newPackage
      )
    )
  }

  const deletePackageAction = async (id: number): Promise<void> => {
    try {
      await packageService.deletePackage(id).then(async () => {
        setInfoModalTitle('Supprimer le package')
        setmodalText('Le package a été supprimé avec succès')
        setIsModalOpen(true)
        const response = await packageService.getAllPackages()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setPackages(response)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenImageModal = async (pa: PackageType): Promise<void> => {
    try {
      setCurrentPackage(pa)
      setIsImageModalOpen(true)
      await fetchImages()
    } catch (error) {
      console.error('Failed to delete image:', error)
    }
  }

  const fetchImages = async (): Promise<void> => {
    const urls = await imageService.listImages()
    const notFoundIndex = urls.findIndex((url) =>
      url.includes('image_placeholder')
    )
    if (notFoundIndex > 0) {
      const [notFoundUrl] = urls.splice(notFoundIndex, 1)
      urls.unshift(notFoundUrl)
    }
    setImageUrls(urls)
  }

  const handleToggle = (pkg: PackageType): void => {
    setSelectedPackage(pkg)
    let packageStatus: boolean = true
    pkg.products?.map((pkg) => {
      if (!pkg.enabled) {
        packageStatus = false
      }
      return packageStatus
    })
    if (packageStatus) {
      setIsConfirmationModalOpen(true)
    } else {
      setInfoModalTitle('Désactiver le package')
      setmodalText(
        "Le package ne peut pas être activé, car l'un des produits est désactivé."
      )
      setIsModalOpen(true)
    }
  }

  const cancelToggle = (): void => {
    setIsConfirmationModalOpen(false)
  }

  const confirmToggle = async (): Promise<void> => {
    if (selectedPackage?.id) {
      const newEnabledStatus = !selectedPackage.enabled
      await packageService.disablePackage(selectedPackage.id, newEnabledStatus)
      const updatedPackages = packages.map((p) =>
        p.id === selectedPackage.id ? { ...p, enabled: newEnabledStatus } : p
      )
      setPackages(updatedPackages)
    }
    setIsConfirmationModalOpen(false)
  }

  const handleRankBlur = (
    pkg: PackageType,
    newRank: number,
    audienceId: number
  ): void => {
    const filteredPackages = packages.filter(
      (p) => p.Audience?.id === pkg.Audience?.id
    )
    if (newRank > filteredPackages.length) {
      newRank = filteredPackages.length
    }
    if (newRank < 1) {
      newRank = 1
    }
    console.log('new Rank: ', newRank)
    setRankToUpdate({ pkg, newRank, audienceId })
    setIsRankConfirmationModalOpen(true) // Open the modal when input loses focus
  }

  const handleRankChange = async (
    pkg: PackageType,
    newRank: number,
    audienceId: number
  ): Promise<void> => {
    try {
      // Call the API to update the rank
      await packageService
        .updatePackageRank(pkg.id, newRank, audienceId)
        .then(async () => {
          const response = await packageService.getAllPackages()
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setPackages(response)
        })
    } catch (error) {
      console.error('Failed to update rank:', error)
    }
  }

  const columns = [
    { header: '', accessor: 'toggle' },
    { header: 'ID', accessor: 'id' },
    {
      header: 'RANG',
      accessor: 'rank',
      render: (row: PackageType) => (
        <>
          <input
            type='number'
            className='rank-input'
            value={row.rank}
            onChange={(e) => {
              const newRank = parseInt(e.target.value, 10)
              setPackages((prevPackages) =>
                prevPackages.map((p) =>
                  p.id === row.id ? { ...p, rank: newRank } : p
                )
              )
            }}
            onKeyDown={(e) => {
              if (!/^\d$/.test(e.key)) {
                e.preventDefault()
              }
            }}
            onBlur={() => {
              const adjustedRank = row.rank && row.rank > 0 ? row.rank : 1
              row.Audience?.id && handleRankBlur(row, adjustedRank, row.Audience?.id)
            }}
          />
        </>
      )
    },
    {
      header: 'AUDIENCE',
      accessor: 'audience',
      render: (row: PackageType) => <>{row.Audience?.name || '--'}</>
    },
    { header: 'FREQUENCE PACKAGE', accessor: 'frequency' },
    { header: 'NOM PACKAGE', accessor: 'label' },
    { header: 'MINIMUM PACKAGE', accessor: 'minToOrder' },
    { header: 'MAXIMUM PACKAGE', accessor: 'maxToOrder' },
    { header: 'POINT QUANTITÉ MINIMUM', accessor: 'minToWinPoints' },
    { header: 'POINT QUANTITÉ MAXIMUM', accessor: 'maxToWinPoints' },
    { header: 'NOMBRE DE POINTS', accessor: 'pointValue' },
    {
      header: 'IMAGES',
      accessor: 'images',
      render: (row: PackageType) => {
        return (
          <>
            <img
              src={imageService.getImageUrl(row.imageUrl || '')}
              alt='Package'
              style={{ width: '50px', height: '50px', cursor: 'pointer' }}
              onClick={async () => {
                await handleOpenImageModal(row)
              }}
            />
          </>
        )
      }
    },
    {
      header: 'ACTIONS',
      accessor: 'actions',
      render: (row: PackageType) => (
        <div className='actions-cell'>
          <EditIcon
            className='admin-icons'
            onClick={() => {
              updatePackage(row)
            }}
            style={{ color: 'green' }}
          >
            Update
          </EditIcon>
          <DeleteIcon
            className='admin-icons'
            onClick={() => {
              setSelectedPackage(row)
              setIsDeleteConfirmationModalOpen(true)
            }}
          >
            Delete
          </DeleteIcon>
          <ToggleButton
            enabled={row.enabled}
            onClick={() => {
              handleToggle(row)
            }}
          />
        </div>
      )
    },
    {
      header: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <DeleteIcon
            className='admin-icons'
            onClick={() => {
              if (selectedPackages.length === 0) {
                console.log('No Package selected.')
              } else {
                setIsBulkDeleteConfirmationModalOpen(true) // Open bulk delete confirmation modal
              }
            }}
            style={{ cursor: 'pointer' }}
          />
          <input
            type='checkbox'
            onChange={(e) => {
              if (e.target.checked) {
                // Select all products if checked
                setSelectedPackages(packages.map((pkg) => pkg.id))
              } else {
                // Deselect all products if unchecked
                setSelectedPackages([])
              }
            }}
            checked={
              selectedPackages.length === packages.length && packages.length > 0
            }
          />
        </div>
      ),
      accessor: 'select',
      render: (row: PackageType) => (
        <input
          type='checkbox'
          checked={selectedPackages.includes(row.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedPackages([...selectedPackages, row.id])
            } else {
              setSelectedPackages(
                selectedPackages.filter((id) => id !== row.id)
              )
            }
          }}
        />
      )
    }
  ]

  useEffect(() => {
    setPackages(packagesList)
  }, [packagesList])

  return (
    <div className='packages-container-list'>
      <InfoModal
        isOpen={isModalOpen}
        title={infoModalTitle}
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />
      <PackageTable
        columns={columns}
        data={packages}
        expandedRowIndex={expandedRowIndex}
        setExpandedRowIndex={setExpandedRowIndex}
        onProductRemoved={handleProductRemoved}
        onProductAdded={handleProductAdded}
        onProductUpdated={handleProductUpdated}
      />
      <Modal
        isOpen={isEditModalOpen}
        title='Modifier un package'
        onClose={() => {
          setIsEditModalOpen(false)
        }}
      >
        <EditPackage
          packageProp={selectedPackage}
          closeModal={() => {
            setIsEditModalOpen(false)
          }}
          onEditPackage={handleEditPackage}
        />
      </Modal>

      <Modal
        isOpen={isImageModalOpen}
        title='Associer une nouvelle image au produit'
        onClose={() => {
          setIsImageModalOpen(false)
        }}
      >
        <AddImagePackage
          closeModal={() => {
            setIsImageModalOpen(false)
          }}
          imageUrls={imageUrls}
          currentPackage={currentPackage}
          setPackages={setPackages}
        />
      </Modal>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title="Indication d'un package en rupture"
        message={
          selectedPackage.enabled
            ? 'Attention, le package ne sera plus visible par le point de vente.'
            : 'Le package sera de nouveau visible pour le point de vente.'
        }
        subMessage={'Confirmez-vous ?'}
        onClose={cancelToggle}
        onConfirme={confirmToggle}
      ></ConfirmationModal>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        title='Supprimer un package'
        message='Voulez-vous supprimer ce package ?'
        onClose={() => {
          setIsDeleteConfirmationModalOpen(false)
        }}
        onConfirme={async () => {
          if (selectedPackage.id) {
            await deletePackageAction(selectedPackage.id)
            setIsDeleteConfirmationModalOpen(false)
          }
        }}
      ></ConfirmationModal>
      <ConfirmationModal
        isOpen={isRankConfirmationModalOpen}
        title='Confirmer la modification du rang'
        message={`Voulez-vous vraiment modifier le rang du package ${rankToUpdate?.pkg.id} à ${rankToUpdate?.newRank}?`}
        onClose={() => {
          setIsRankConfirmationModalOpen(false)
        }}
        onConfirme={async () => {
          if (rankToUpdate) {
            await handleRankChange(
              rankToUpdate.pkg,
              rankToUpdate.newRank,
              rankToUpdate.audienceId
            )
            setIsRankConfirmationModalOpen(false)
          }
        }}
      />
            <ConfirmationModal
        isOpen={isBulkDeleteConfirmationModalOpen}
        title='Confirmer la suppression en masse'
        message={`Êtes-vous sûr de vouloir supprimer les packages suivants: ${selectedPackages.join(', ')}?`}
        onClose={() => { setIsBulkDeleteConfirmationModalOpen(false) } }
        onConfirme={async () => {
          try {
            await packageService.bulkDeletePackages(selectedPackages)
            setSelectedPackages([]) // Clear selected packages
            const updatedPackages = await packageService.getAllPackages()
            setPackages(updatedPackages)
            setIsBulkDeleteConfirmationModalOpen(false) // Close modal after deletion
          } catch (error) {
            console.log('Failed to delete packages')
          }
        }}
      />
    </div>
  )
}

export default ListPackage
