import { type PackageType, type ProductType } from './types'

function formatImageUrl (imageUrl: string): string {
  const apiBaseUrl = process.env.REACT_APP_API_URL
  if (imageUrl.startsWith('http')) {
    return imageUrl
  } else {
    return `${apiBaseUrl}/images/${imageUrl}`
  }
}
function formatProductData (data: ProductType): any {
  return {
    audienceId: data.Audience?.id,
    brand: data.brand,
    code: data.code,
    label: data.label,
    minToOrder: data.minToOrder,
    maxToOrder: data.maxToOrder,
    minToWinPoints: data.minToWinPoints,
    maxToWinPoints: data.maxToWinPoints,
    pointValue: data.pointValue,
    imageUrl: data.imageUrl,
    enabled: data.enabled
  }
}
function formatPackageData (data: PackageType): any {
  return {
    audienceId: data.Audience?.id,
    code: data.code,
    label: data.label,
    minToOrder: data.minToOrder,
    maxToOrder: data.maxToOrder,
    minToWinPoints: data.minToWinPoints,
    maxToWinPoints: data.maxToWinPoints,
    pointValue: data.pointValue,
    imageUrl: data.imageUrl,
    frequency: data.frequency,
    enabled: data.enabled
  }
}
function extractImageNameFromUrl (imageUrl: string): string | null {
  const urlWithSpaces = imageUrl.replace(/%20/g, ' ')
  const segments = urlWithSpaces.split('/')
  const imageName = segments[segments.length - 1].split('?')[0]
  const match = imageName.match(/__(.*?)__/)
  return match ? match[1] : null
}

function getImageName (imageUrl: string): string | null {
  const segments = imageUrl.split('/')
  const imageName = segments[segments.length - 1].split('?')[0]
  const match = imageName.match(/__(.*?)__/)
  return match ? match[1] : null
}

export { formatImageUrl, formatProductData, extractImageNameFromUrl, getImageName, formatPackageData }
